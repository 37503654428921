<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
          <h3 class="font-weight-bolder text-nowrap mb-0">Discount</h3>
          <div class="form-col-select ml-2">
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <feather-icon size="18" icon="ChevronDownIcon" />
          </div>
          <div class="search-primary w-100">
            <feather-icon size="16" icon="SearchIcon" />
            <b-form-input v-model="searchQuery" class="d-inline-block" placeholder="Find Discount" />
          </div>
          <b-button variant="primary" class="px-3 mobile-w100" :to="{ name: 'discount-create' }">
            <span class="text-nowrap"><feather-icon size="14" icon="PlusIcon" />Discount</span>
          </b-button>
        </div>
      </div>

      <b-table ref="refDiscountListTable" class="position-relative" :items="fetchDiscounts" responsive
        :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc">
        <template #cell(type)="data">
          <b-col cols="12">
            {{ data.item.type === 'referral' ? 'Referral' : data.item.type === 'promotional' ? 'Promotional' :
              'Individual' }}
          </b-col>
        </template>
        <template #cell(discount_type)="data">
          <b-col cols="12">
            {{ data.item.discount_type === 'percentage' ? data.item.discount_value + '% (Percentage)' :
              data.item.discount_type ===
                'fixed' ?
                'NPR' + data.item.discount_value + ' (Fixed)' : data.item.discount_value }}
          </b-col>
        </template>
        <template #cell(is_active)="data">
          <b-col cols="12">
            <b-form-checkbox :checked="data.item.is_active ? true : false" @change="updateStatus(data.item.id)"
              class="custom-control-success" name="check-button" switch inline />
          </b-col>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown class="action-trigger-btn" :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="EyeIcon" size="16" class="align-middle text-body" />
            </template>
            <!-- v-if="ability.can('read', 'user')" -->
            <!-- <b-dropdown-item
              :to="{ name: 'discount-view', params: { id: data.item.id } }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item> -->

            <!-- v-if="ability.can('update', 'user')" -->
            <b-dropdown-item :to="{ name: 'discount-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="handleDelete(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalDiscounts" :per-page="perPage" first-number
              last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBModal,
  BFormGroup,
  BForm,
  BOverlay,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useDiscountsList from "./useDiscountsList";
import discountStoreModule from "../discountStoreModule";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    AppTimeline,
    AppTimelineItem,
    VBModal,
    BFormGroup,
    BForm,
    BOverlay,
    BFormCheckbox,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  methods: {
    updateStatus(id) {
      this.loading = true;
      store
        .dispatch("app-discounts/updateStatus", id)
        .then((response) => {
          this.loading = false;
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
          this.refetchData();
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            text: JSON.stringify(error.response.data.message),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
    handleDelete(id) {
      this.$swal({
        title: "Are you sure?",
        text: "Your action is final and you will not be able to retrieve the discount record.",
        showCancelButton: true,
        confirmButtonText: "Delete",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("app-discounts/deleteDiscount", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  data() {
    return {
    };
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-discounts";

    const uploadUrl = ref(process.env.VUE_APP_IMAGE_URL);
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(
        USER_APP_STORE_MODULE_NAME,
        discountStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const {
      fetchDiscounts,
      tableColumns,
      perPage,
      currentPage,
      totalDiscounts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDiscountListTable,
      refetchData,

      // Extra Filters
      statusFilter,
      is_active,
      ability,
    } = useDiscountsList();

    return {

      fetchDiscounts,
      tableColumns,
      perPage,
      currentPage,
      totalDiscounts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDiscountListTable,
      refetchData,
      ability,

      // Filter
      avatarText,

      // Extra Filters
      statusFilter,
      is_active,

      uploadUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
